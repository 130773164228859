import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Home = (props) => {
  const {} = props;

  const [notifyVisible, setNotifyVisible] = useState(false);

  const [fastList, setFastList] = useState([
    {
      title: "FileStation Manager®",
      intro: "专为存储设计的操作系统服务",
      link: "",
    },
    {
      title: "快连组网",
      intro: "全域私有网络管理 专属你的安全网络",
      link: "",
    },
    {
      title: "快连远程桌面",
      intro: "高速稳定的远程桌面协作服务",
      link: "",
    },
    {
      title: "Ebackup Manager",
      intro: "本地、异地多点多渠道备份管理服务",
      link: "",
    },
  ]);

  const [familyList, setFamilyList] = useState([
    {
      title: "影音中心",
      intro: "打造专属家庭的私人影院",
    },
    {
      title: "智能相册",
      intro: "家庭成长记录、留住时刻美好",
    },
    {
      title: "监控中心",
      intro: "智能监控设备接入，私有化录制回看",
    },
    {
      title: "智能家居",
      intro: "私有管理，独立网络隔离的统一平台",
    },
    {
      title: "家庭组网",
      intro: "专属于家庭的私有化网络",
    },
    {
      title: "备份服务",
      intro: "家庭设备远程备份中心",
    },
    {
      title: "家庭财务小助手",
      intro: "家庭收支明细记录管理服务(独立隐私)",
    },
    {
      title: "养娃神器",
      intro: "出生养娃全掌握(独立隐私)",
    },
  ]);

  const [backupsList, setBackupsList] = useState([
    {
      title: "智能云盘",
      subtitle: "FileManager",
      intro:
        "FSM操作系统内置云盘服务，可以通过文件协议SMB/WebDAV/FTP等方式，无缝接入至您的手机/电脑/平板等设备中。（FSM操作系统® 文件管理服务支持Windows/MacOS/Linux/Andriod/IOS等操作系统）",
    },
    {
      title: "数据备份",
      subtitle: "Ebackup Manager",
      intro:
        "通过Ebackup Manager 软件管理器，轻松备份您的操作系统文件，系统级备份，支持根据镜像统一恢复，或按照文件对比进行恢复数据资料。多种方式，省心省力。",
    },
    {
      title: "统一门户",
      subtitle: "OAuth Center",
      intro:
        "FSM操作系统®提供了完整的统一认证体系，可以便于用户轻松集成现有系统，现有业务流程至FSM业务生态，通过内置沟通工具IM客户端可以快速访问，文件、消息、通知流转更轻松。",
    },
    {
      title: "影音媒体",
      subtitle: "Media Service",
      intro:
        "内置媒体管理服务视频中心、智能相册、音频博客，可以构建私有的影音素材服务器。个人和家庭可以构建私有家庭影院服务。企业用户可以通过媒体服务构建企业宣传资料存储与归纳，企业统一分支机构或宣传门户的统一管控和展播服务。",
    },
  ]);

  const [partners, setPartners] = useState([
    {
      title: "珑微系统",
    },
    {
      title: "湃鲸科技",
    },
    {
      title: "领优科技",
    },
  ]);

  useEffect(() => {
    document.title = "七朵云 - 存储解决方案服务商";

    if (!localStorage.getItem("isShowNotify")) {
      setNotifyVisible(true);
      localStorage.setItem("isShowNotify", 1);
    }
  }, []);
  return (
    <div className="home context">
      <div className="carousel">
        <Carousel autoplay>
          <div className="banner-item">
            <div className="item">
              <div className="info">
                <div className="title">FileStaion Manager ®</div>
                <div className="subtitle">为存储设计的统一操作系统软件</div>
                <div className="btn-cont">
                  <Button className="primary" type="primary">
                    了解详情
                  </Button>
                  <Button className="link" type="link">
                    去试用
                  </Button>
                </div>
              </div>
              <div className="cover">
                <img
                  src={require("../../../assets/image/home/banner.svg").default}
                />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div
        className="fast-cont"
        style={{
          borderTop: "1px solid #eee",
          borderBottom: "1px solid #eee",
        }}
      >
        <div
          className="container"
          style={{
            padding: 0,
          }}
        >
          <div className="block fast-list">
            {fastList.map((item, ind) => (
              <div className="fast-list-item" key={ind}>
                <a href={item.link} target="_blank">
                  <img
                    className="cover"
                    src={
                      require(`../../../assets/image/home/fasticon${
                        ind + 1
                      }.svg`).default
                    }
                  />
                  <div className="info">
                    <div className="title">{item.title}</div>
                    <div className="intro">{item.intro}</div>
                  </div>
                  <div className="more">
                    <RightCircleOutlined />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="block family">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>全家人的美好回忆 共享家的乐趣</span>
                <small>了解更多家庭场景使用帮助</small>
              </div>
              <div className="subtitle">
                一键上传视频、照片、家庭记事、文档等文件，美好记忆不丢失
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="family-list">
              {familyList.map((item, ind) => (
                <div className="family-list-item" key={ind}>
                  <img
                    className="cover"
                    src={
                      require(`../../../assets/image/home/familyicon${
                        ind + 1
                      }.svg`).default
                    }
                  />
                  <div className="info">
                    <div className="title">{item.title}</div>
                    <div className="intro">{item.intro}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="block ai">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>引领、变革。数字化时代，数据+ AI</span>
              </div>
              <div className="subtitle">基于数字化，结合AI强化数据管理服务</div>
            </div>
          </div>
          <div className="cont">
            <img src={require("../../../assets/image/home/ai.jpg").default} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="block backups">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>高效、安全的一体化应用与备份服务</span>
              </div>
              <div className="subtitle">
                一个操作系统，轻松管理家庭与企业关键数据
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="backups-list">
              {backupsList.map((item, ind) => (
                <div className="backups-list-item" key={ind}>
                  <div className="info">
                    <img
                      className="cover"
                      src={
                        require(`../../../assets/image/home/backupsicon${
                          ind + 1
                        }.svg`).default
                      }
                    />
                    <div className="info-cont">
                      <div className="title">{item.title}</div>
                      <div className="subtitle">{item.subtitle}</div>
                    </div>
                  </div>
                  <div className="intro">{item.intro}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="foot">
            <div className="tip">
              FSM操作系统®
              基于LINUX构建，采用分布式操作系统部署方式，即使设备存在故障，也能有效的保障数据的可靠性和一致性，确保数据安全。我们深知数据安全的重要性，安全可靠是我们一致的目标。
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="block tools">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>一站式办公效率工具</span>
              </div>
              <div className="subtitle">
                中小企业轻松解决全流程电子化办公协作
              </div>
            </div>
            <Button className="primary" type="primary">
              了解详情
            </Button>
          </div>
          <div className="cont"></div>
        </div>
      </div>

      <div className="container">
        <div className="block partners">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>平台+生态，与伙伴联合创新</span>
              </div>
              <div className="subtitle">
                增强数据安全、高效管理数据、释放数据价值
              </div>
            </div>
          </div>
          <div className="cont">
            <div className="partners-list">
              {partners.map((item, ind) => (
                <div className="partners-list-item" key={ind}>
                  <img
                    className="logo"
                    src={
                      require(`../../../assets/image/home/partners${
                        ind + 1
                      }.svg`).default
                    }
                  />
                </div>
              ))}
            </div>
            <Button className="primary" type="primary">
              了解详情
            </Button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="block service">
          <div className="head">
            <div className="title-cont">
              <div className="title">
                <span>平台+全域私有化解决方案提供商</span>
              </div>
              <div className="subtitle">打造安全可控的私有网络数据管理服务</div>
            </div>

            <Button className="primary" type="primary">
              马上咨询
            </Button>
          </div>
        </div>
      </div>

      <Modal
        getContainer={false}
        open={notifyVisible}
        width={650}
        footer={false}
        okText={"保存"}
        cancelText={"取消"}
        onOk={() => {}}
        onCancel={() => setNotifyVisible(false)}
        destroyOnClose
        className="modal-notify"
        centered
      >
        <div className="content">
          <h3 className="title mb-4">服务升级通知！</h3>
          <p className="tip">
            尊敬的用户您好，为了更好的提供服务，七朵云将于2024年9月7日09时起升级为统一登录认证系统，请您按照新账号的模式注册一个新账号，登录官网时请验证一下您原来注册的手机号即可完成绑定。如您的账号不存在，请扫描官网尾部的联系我微信二维码处理账号合并问题。
          </p>
          <p className="tip">登录流程提示：</p>
          <p className="tip">第一步：注册统一用户认证系统注册新账户</p>
          <p className="tip">第二步：登录官网系统（由统一认证系统登录授权）</p>
          <p className="tip">
            第三步：登录返回以后如账号不存在或未绑定可以通过手机号进行验证绑定
          </p>
          <p className="tip">
            完成上述步骤即可完成升级，如有问题可以联系官网客服处理。
          </p>
          <p className="tip">
            我们计划将在每两周内对官网和各项服务进行一次升级改造，以便完成对后续的FSM操作系统生态的支持。也欢迎大家在我们升级以后提出宝贵的建议和想法。
          </p>
          <p className="tip">感谢您对七朵云的支持！</p>
          {/* <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p> */}
        </div>
      </Modal>
    </div>
  );
};

export default Home;

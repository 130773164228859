import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, Link } from "react-router-dom";
import { Spin, Layout, Card, Menu, Button, Dropdown } from "antd";
import {
  DownOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  ApartmentOutlined,
  ClusterOutlined,
  MoneyCollectOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";

import { GET_INFO, REFRESH_TOKEN, LOGIN_OUT } from "../../api/login";

import cookie from "react-cookies";
import "./index.less";

//公共函数
import utils from "../../assets/js/public";
import basicRouters from "../../assets/json/basicRouters.json";

//页面引入
import Overview from "./overview"; //概览
import Device from "./device"; //设备
import Order from "./order"; //订单
import AgentDevice from "./agent/device"; //代理商授权购买

import { ReactComponent as OverviewIcon1 } from "../../assets/image/usercent/menu-1.svg";
import { ReactComponent as OverviewIcon2 } from "../../assets/image/usercent/menu-2.svg";
import { ReactComponent as OverviewIcon3 } from "../../assets/image/usercent/menu-3.svg";
import { ReactComponent as OverviewIcon4 } from "../../assets/image/usercent/menu-4.svg";
import { ReactComponent as ShareIcon } from "../../assets/image/usercent/share.svg";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const UserCenter = (props) => {
  const history = useHistory();

  const [userInfo, setUserInfo] = useState();
  const [curMenu, setCurMenu] = useState("");

  const isLogin = async () => {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      localStorage.removeItem("userInfo");
      history.push("/");
      return true;
    }

    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    return false;
  };

  const getUserInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_INFO();
    if (code == 200) {
      // console.log(res.data)
      setUserInfo(data);
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  };

  const menus = [
    {
      label: <Link to="/usercenter/overview">总览</Link>,
      key: "/usercenter/overview",
      icon: (
        // <img src={require("../../assets/image/usercent/menu-1.svg").default} />
        <OverviewIcon1 />
      ),
    },
    {
      label: "我的订单",
      key: "order",
      icon: <OverviewIcon3 />,
      children: [
        {
          label: <Link to="/usercenter/order/list">订单列表</Link>,
          key: "/usercenter/order/list",
        },
      ],
    },
    {
      label: "我的卡券",
      key: "coupon",
      icon: <AccountBookOutlined style={{ fontSize: 20 }} />,
      children: [
        {
          label: <Link to="/usercenter/coupon/list">优惠券</Link>,
          key: "/usercenter/coupon/list",
        },
        {
          label: <Link to="/usercenter/gift/list">礼品卡</Link>,
          key: "/usercenter/gift/list",
        },
      ],
    },
    {
      label: (
        <a
          className="d-flex align-items-center justify-content-between"
          href="https://authorize.qiduo.net"
          target="_blank"
        >
          <span>软件授权</span>
          <ShareIcon
            style={{ fontSize: 14, width: 14, height: 14, color: "#666" }}
          />
        </a>
      ),
      key: "https://authorize.qiduo.net",
      icon: (
        // <img
        //   src={require("../../assets/image/usercent/menu-3.svg").default}
        // />

        <ApartmentOutlined style={{ fontSize: 20 }} />
      ),
    },
    {
      label: (
        <a
          className="d-flex align-items-center justify-content-between"
          href="https://sd-wan.qiduo.net"
          target="_blank"
        >
          <span>异地组网</span>
          <ShareIcon
            style={{ fontSize: 14, width: 14, height: 14, color: "#666" }}
          />
        </a>
      ),
      key: "https://sd-wan.qiduo.net",
      icon: (
        // <img
        //   src={require("../../assets/image/usercent/menu-3.svg").default}
        // />

        <ClusterOutlined style={{ fontSize: 20 }} />
      ),
    },
    {
      label: (
        <a
          className="d-flex align-items-center justify-content-between"
          href="https://support.qiduo.net"
          target="_blank"
        >
          <span>工单中心</span>
          <ShareIcon
            style={{ fontSize: 14, width: 14, height: 14, color: "#666" }}
          />
        </a>
      ),
      key: "https://support.qiduo.net",
      icon: (
        // <img
        //   src={require("../../assets/image/usercent/menu-3.svg").default}
        // />

        <OverviewIcon4 />
      ),
    },
    // {
    //   label: <Link to="/usercenter/finance">财务管理</Link>,
    //   icon: (
    //     // <img
    //     //   src={require("../../assets/image/usercent/menu-3.svg").default}
    //     // />
    //     <MoneyCollectOutlined style={{ fontSize: 20 }} />
    //   ),
    // },
    // {
    //   label: <Link to="/usercenter/my">个人信息</Link>,
    //   icon: (
    //     // <img
    //     //   src={require("../../assets/image/usercent/menu-3.svg").default}
    //     // />
    //     <UserOutlined style={{ fontSize: 20 }} />
    //   ),
    // },
    // // {
    // //   label: "设备管理",
    // //   key: "device",
    // //   children: [
    // {
    //   label: <Link to="/usercenter/device/list">我的设备</Link>,
    //   key: "/usercenter/device/list",
    //   icon: (
    //     // <img
    //     //   src={require("../../assets/image/usercent/menu-2.svg").default}
    //     // />
    //     <OverviewIcon2 />
    //   ),
    // },
    //   ],
    // },
    // {
    //   label: "售后服务",
    //   key: "service",
    //   children: [
    //     {
    //       label: <Link to="/usercenter/service/list">工单管理</Link>,
    //       key: "/usercenter/service/list",
    //       icon: (
    //         // <img
    //         //   src={require("../../assets/image/usercent/menu-4.svg").default}
    //         // />

    //         <OverviewIcon4 />
    //       ),
    //     },
    //   ],
    // },
    // {
    //   label: "代理商管理",
    //   key: "agent",
    //   type: "group",
    //   isAgent: true,
    //   children: [
    //     {
    //       label: <Link to="/usercenter/agent/device">授权购买</Link>,
    //       key: "/usercenter/agent/device",
    //       icon: <UserOutlined />,
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    isLogin();
    getUserInfo();
  }, []);

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    // console.log(history.location.pathname);
    setCurMenu(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <div className="usercenter">
      <Layout hasSider>
        <Sider>
          <div className="logo py-2 pb-3" style={{ textAlign: "center" }}>
            <a href="/">
              <img src={require("../../assets/image/logo1.png").default} />
            </a>
          </div>
          <Menu
            theme="light"
            mode="inline"
            // openKeys={["6"]}
            selectedKeys={[curMenu]}
            // defaultSelectedKeys={[curMenu]}
            items={[
              ...menus.filter((item) => {
                // 过滤代理商菜单
                if (!!item?.isAgent && !!userInfo?.agent_id) {
                  return item;
                } else {
                  if (!item?.isAgent) {
                    return item;
                  }
                }
              }),
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Content>
            <Switch>
              {/* 概览 */}
              <Route path="/usercenter/overview" exact component={Overview} />
              <Route path="/usercenter/order/list" exact component={Order} />
              <Route path="/usercenter/device/list" exact component={Device} />
              <Route
                path="/usercenter/agent/device"
                exact
                component={AgentDevice}
              />

              <Redirect to={`/usercenter/overview`} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default UserCenter;

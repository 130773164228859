import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取商城轮播图列表
 * @param {Object} parm 请求参数
 * @returns
 */
export const GET_IMAGES_SHOP_LIST = async (parm) =>
  await instance.get(`${base}/images/shop`, parm);

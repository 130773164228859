import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import {
  GET_GOODS_CATEGORY_LIST,
  GET_GOODS_CATEGORY_DETAIL,
} from "../../../api/goodsCategory";

import { GET_IMAGES_SHOP_LIST } from "../../../api/images";

const Product = (props) => {
  const {} = props;
  const _h = useHistory();

  const [goodsList, setGoodsList] = useState([]);
  const [banners, setBanners] = useState([]);

  // 获取商品分类
  const getGoodsCategory = async () => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_CATEGORY_LIST();
    if (code == 200) {
      console.log(data.resource.filter((f) => f.goods.length > 0));

      setGoodsList([...data.resource.filter((f) => f.goods.length > 0)]);
    }
  };

  // 获取轮播图
  const getBanner = async () => {
    const {
      data: { data, code, msg },
    } = await GET_IMAGES_SHOP_LIST();
    if (code == 200) {
      setBanners([...data]);
    }
  };

  useEffect(() => {
    document.title = "商城";

    getGoodsCategory();
    getBanner();
  }, []);
  return (
    <div className="product shop">
      <div className="container">
        <div className="banner">
          <Carousel autoplaySpeed={5000} speed={1500} autoplay>
            {(banners.length > 0 &&
              banners.map((item, ind) => (
                <div className="banner-item" key={ind}>
                  <a href={item.link} target="_blank">
                    <img className="cover" src={item.src} />
                  </a>
                </div>
              ))) ||
              ""}
          </Carousel>
        </div>
      </div>
      {/* <div className="container">
        <div className="block hot">
          <div className="block-header">
            <div className="title">热销商品</div>
          </div>
          <div className="goods-list">
            <div className="goods-list-item">
              <div className="cover"></div>
              <div className="cont-info">
                <div className="title"></div>
                <div className="intro"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {goodsList.map((item, ind) => (
        <div className="container">
          <div className="block" key={ind}>
            <div className="block-header">
              <div
                className="title"
                onClick={() => {
                  window.location.href = `/shop/list/${item.id}`;
                }}
              >
                {item.name}
              </div>
            </div>
            <div className="goods-list">
              {item.goods.map((gitem, gind) => (
                <div
                  className="goods-list-item"
                  key={gind}
                  onClick={() => {
                    // _h.push(`/shop/detail/${item.id}`);
                    window.location.href = `/shop/detail/${gitem.id}`;
                  }}
                >
                  <div className="cover">
                    <img className="img" src={gitem.cover} />
                  </div>
                  <div className="cont-info">
                    <div className="title">{gitem.name}</div>
                    <div className="intro">{gitem.intro}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Product;

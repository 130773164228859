import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Carousel,
  List,
  Spin,
  Skeleton,
  Divider,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { GET_GOODS_CATEGORY_DETAIL } from "../../../api/goodsCategory";
import { GET_GOODS_LIST } from "../../../api/goods";
import InfiniteScroll from "react-infinite-scroll-component";

const ProductList = (props) => {
  const {} = props;
  const _h = useHistory();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 8,
    pageNo: 1,
    total: 0,
  });
  const [list, setList] = useState([]);
  const [classifyInfo, setClassifyInfo] = useState({});

  // 获取当前分类信息
  const getClassifyInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_CATEGORY_DETAIL({
      id: Number(props.match.params.id),
    });
    if (code == 200) {
      setClassifyInfo(data);
    }
  };

  // 获取商品列表
  const loadMoreData = async (paginations) => {
    if (loading) {
      return;
    }
    console.log(paginations);
    setLoading(true);

    const {
      data: { data, code, msg },
    } = await GET_GOODS_LIST({
      ...paginations,
      where: {
        category_id: Number(props.match.params.id),
      },
    });

    if (200 == code) {
      console.log(data);
      setList([...list, ...data.resource]);
      setPagination({
        ...paginations,
        total: data.total,
      });
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = "商城";
    getClassifyInfo();
    loadMoreData({ ...pagination });
  }, []);
  return (
    <div className="product shop">
      <div className="container">
        <div className="block">
          <div className="block-header">
            <div className="title">{classifyInfo?.name || "-"}</div>
          </div>
          <InfiniteScroll
            dataLength={list.length}
            next={() => {
              loadMoreData({ ...pagination, pageNo: pagination.pageNo + 1 });
            }}
            hasMore={list.length < pagination.total}
            loader={
              <div className="p-5 text-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 44 }} />}
                />
              </div>
            }
            endMessage={
              <div className="nomore mt-5">
                <Divider plain>没有更多了</Divider>
              </div>
            }
          >
            <div className="goods-list">
              {list.map((item, ind) => (
                <div
                  className="goods-list-item"
                  key={ind}
                  onClick={() => {
                    // _h.push(`/shop/detail/${item.id}`);
                    window.location.href = `/shop/detail/${item.id}`;
                  }}
                >
                  <div className="cover">
                    <img className="img" src={item.cover} />
                  </div>
                  <div className="cont-info">
                    <div className="title">{item.name}</div>
                    <div className="intro">{item.intro}</div>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
